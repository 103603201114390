import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';

const pages = [
    { name: 'Suggest', path: '/suggest' },
    //{ name: 'Watchlist', path: '/suggestions' },
    { name: 'About', path: '/about' },
    { name: 'Discord', external: true, path: 'https://discord.gg/hCB2vU7UHv' },
];

function CustomBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{bgcolor: 'black'}} elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

		  {/* Logo for desktop */}
		  <Avatar alt="" src="./logo.gif" component="a" href="https://www.youtube.com/@eliteari" target="_blank" sx={{mr: 1, display: {xs:'none', md:'flex'}}}/>
          <Typography
            variant="subtitle1"
            noWrap
            component="a"
            href="https://www.youtube.com/@eliteari"
            target="_blank"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            EliteAri
          </Typography>

          {/* Fallback logo for mobile */}
		  <Avatar alt="" src="./logo.gif" component="a" href="https://www.youtube.com/@eliteari" target="_blank" sx={{mr: 1, display: {xs:'flex', md:'none'}}}/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://www.youtube.com/@eliteari"
            target="_blank"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 400,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            EliteAri
          </Typography>
            
          {/* Dropdown menu for Mobile */}
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>

            {/* Tray bar icon (three horizontal lines)*/}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            {/* Actual dropdown menu */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                page.external ? 
                (
                    <MenuItem key={page} onClick={handleCloseNavMenu} component={Link} to={page.path} target="_blank">
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                ) : (
                    <MenuItem key={page} onClick={handleCloseNavMenu} component={Link} to={page.path}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                )
              ))}
            </Menu>

          </Box>

          {/* Menu items for desktop */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'flex-end' } }}>
            {pages.map((page) => (
              page.external ?
              (
                  <Button
                    key={page}
                    sx={{ my: 0, color: 'white', display: 'block' }}
                    component={Link} to={page.path} target="_blank"
                  >
                    <Typography variant="subtitle1" sx={{fontWeight: 700, textTransform: 'none'}}>{page.name}</Typography>
                  </Button>
              ) : (
                  <Button
                    key={page}
                    sx={{ my: 0, color: 'white', display: 'block' }}
                    component={Link} to={page.path}
                  >
                    <Typography variant="subtitle1" sx={{fontWeight: 700, textTransform: 'none'}}>{page.name}</Typography>
                  </Button>
              )
            ))}
          </Box>

        </Toolbar>
      </Container>
    </AppBar>

  );
}

export default CustomBar;
