import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Divider, Button, Container, Paper, InputBase, IconButton, Box, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';

function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

function getMediaType(x){
	if (x ===  "tv") {
		return "TV Series";
	}
	if (x === "movie") {
		return "Movie";
	}

	return "Misc. Media";
}

function getReleaseDate(x){
	if (typeof x == 'undefined'){
		return "Year Unknown";
	}
	return x.split('-')[0];
}

const Suggest = ({ addToList, myList }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
	const [hoveredCard, setHoveredCard] = useState(null);
	const [clickedCard, setClickedCard] = useState(null);
    const [movieToConfirm, setMovieToConfirm] = useState(null);
    const [searchAttempted, setSearchAttempted] = useState(null);
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

    const handleAccordionChange = (event, isExpanded) => {
      setIsAccordionExpanded(isExpanded);
    };

    const handleClick = (movie) => {
      setClickedCard(movie.id);
      addToList(movie);
      setTimeout(() => setClickedCard(null), 80); // Reset after 150ms
      setQuery('');
      setResults([]);
      setMovieToConfirm(null); // Clear the confirmation state
      setSearchAttempted(false);
    };

    const searchMovies = async (e) => {
        e.preventDefault();

        // Update the URL to point to your Flask backend endpoint
        const url = `https://eliteari.pythonanywhere.com/search_movies?query=${query}`;

        try {
            const response = await axios.get(url);
            setResults(response.data.results.slice(0,6));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setSearchAttempted(true);
    };


    return (
        <Container sx={{width: '100%', padding:'20px'}}>
            <Typography variant="h5" gutterBottom sx={{marginBottom: '15px', textAlign: 'center', fontWeight: 700}}>Suggest things for me to watch!</Typography>
            <form onSubmit={searchMovies}>
                <Box sx={{display:'flex', justifyContent: 'center'}}>
                    <Paper 
                        component="div"
                        sx={{ p: '2px 4px', marginBottom: '15px', display: 'flex', width: 400 }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search for a movie, anime, TV show..."
                        inputProps={{ 'aria-label': 'search google maps' }}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        {query ? <CloseIcon onClick={() => { setQuery(''); setSearchAttempted(false); }} /> : <SearchIcon />}
                      </IconButton>
                    </Paper>
                </Box>
            </form>
            
            {results.length === 0 && query.trim() !== '' && searchAttempted === true ? (
                <Typography variant="subtitle1" sx={{ textAlign: 'center', marginTop: '20px' }}>
                    No results found. Try another search!
                </Typography>
            ) : (
                <Grid container spacing={2}>
                        {results.map((movie) => (
                            <Grid item key={movie.id} xs={12} sm={6} md={4}>
                                <Card
                                    onClick={() => setMovieToConfirm(movie)}
                                    onMouseEnter={() => setHoveredCard(movie.id)}
                                    onMouseLeave={() => setHoveredCard(null)}
                                    style={{
                                      cursor: 'pointer',
                                      backgroundColor: hoveredCard === movie.id ? '#ececec' : 'transparent', // Change color on hover
                                      transform: clickedCard === movie.id ? 'scale(0.97)' : 'scale(1)', // Press-down effect
                                      transition: 'transform 150ms ease-in-out, background-color 150ms ease-in-out', // Smooth transition for both transform and background color
                                    }}
                                >
                                {movieToConfirm && movieToConfirm.id === movie.id ? (
                                    <Container sx={{paddingBottom:'20px', paddingTop: '20px', textAlign: 'center'}}>
                                        <Typography sx={{marginBottom: '10px'}}>Are you sure you want to suggest: <strong>{movie.name || movie.title}</strong>?</Typography>
                                        <Container sx={{display:'flex', justifyContent: 'center', flexDirection: 'row'}}>
                                            <Button variant="contained" color="success" onClick={(e) => { e.stopPropagation(); handleClick(movie); }}><strong>Yes</strong></Button>
                                            <Button sx={{marginLeft:'5px'}} variant="contained" color="error" onClick={(e) => { e.stopPropagation(); setMovieToConfirm(null); }}><strong>No</strong></Button>
                                        </Container>
                                    </Container>
                                ) : (
                                    <>
                                        {movie.poster_path ? (
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                                alt={movie.title || movie.name}
                                            />
                                        ) : (
                                            <div style={{ height: '140px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                                                No Image Available
                                            </div>
                                        )}
                                        <CardContent>
                                            <Typography variant="h6" component="div">
                                                {truncateText(movie.title || movie.name,25)}
                                            </Typography>
                                            <Typography variant="body1" component="div">
                                                {getMediaType(movie.media_type)} ({getReleaseDate(movie.release_date||movie.first_air_date)})
                                            </Typography>
                                        </CardContent>
                                    </>
                                )}
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            )}

            <div style={{ marginTop: '20px' }}>
                <Accordion expanded={isAccordionExpanded} onChange={handleAccordionChange}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant="h6">
                            Pending Suggestions
                        </Typography>
                    </AccordionSummary>
                    <Divider/>
                    <AccordionDetails sx={{marginTop: '10px'}}>
                        {myList.slice().reverse().filter(movie => !movie.approved).length === 0 ? (
                            <Typography variant="subtitle1" sx={{textAlign: 'center'}}>No Pending Suggestions.</Typography>
                        ) : (
                        <Grid container spacing={2}>
                            {myList.slice().reverse().filter(movie => !movie.approved).map((movie) => (
                                <Grid item key={movie.id} xs={12} sm={6} md={4}>
                                    <Card style={{ opacity: movie.approved ? 1 : 0.5 }}>
                                        {movie.poster_path ? (
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                                alt={movie.title || movie.name}
                                            />
                                        ) : (
                                            <div style={{ height: '140px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                                                No Image Available
                                            </div>
                                        )}
                                        <CardContent>
                                            <Typography variant="h6" component="div">
                                                {truncateText(movie.title || movie.name,25)}
                                            </Typography>
                                            <Typography variant="body1" component="div">
                                                {getMediaType(movie.media_type)} ({getReleaseDate(movie.release_date||movie.first_air_date)})
                                            </Typography>
                                            
                                            {movie.approved ? (
                                                <Box sx={{backgroundColor: 'green', padding:0.5, borderRadius:2, color:'white', maxWidth: 'fit-content'}}><strong>Approved</strong></Box>
                                            ) : (
                                                <Box sx={{backgroundColor: 'red', padding:0.5, borderRadius:2, color:'white', maxWidth: 'fit-content'}}><strong>Pending Approval</strong></Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{marginTop: '10px'}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant="h6">
                            Approved Suggestions
                        </Typography>
                    </AccordionSummary>
                    <Divider/>
                    <AccordionDetails sx={{marginTop: '10px'}}>
                        <Grid container spacing={2}>
                            {myList.slice().reverse().filter(movie => movie.approved && !movie.ari_approved).map((movie) => (
                                <Grid item key={movie.id} xs={12} sm={6} md={4}>
                                    <Card style={{ opacity: movie.approved ? 1 : 0.5 }}>
                                        {movie.poster_path ? (
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                                alt={movie.title || movie.name}
                                            />
                                        ) : (
                                            <div style={{ height: '140px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                                                No Image Available
                                            </div>
                                        )}
                                        <CardContent>
                                            <Typography variant="h6" component="div">
                                                {truncateText(movie.title || movie.name,25)}
                                            </Typography>
                                            <Typography variant="body1" component="div">
                                                {getMediaType(movie.media_type)} ({getReleaseDate(movie.release_date||movie.first_air_date)})
                                            </Typography>
                                            
                                            {movie.approved ? (
                                                <Box sx={{backgroundColor: 'green', padding:0.5, borderRadius:2, color:'white', maxWidth: 'fit-content'}}><strong>Approved</strong></Box>
                                            ) : (
                                                <Box sx={{backgroundColor: 'red', padding:0.5, borderRadius:2, color:'white', maxWidth: 'fit-content'}}><strong>Pending Approval</strong></Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{marginTop: '10px'}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant="h6">
                            Ari's Personal Radar
                        </Typography>
                    </AccordionSummary>
                    <Divider/>
                    <AccordionDetails sx={{marginTop: '10px'}}>
                        <Grid container spacing={2}>
                            {myList.slice().reverse().filter(movie => movie.ari_approved).map((movie) => (
                                <Grid item key={movie.id} xs={12} sm={6} md={4}>
                                    <Card style={{ opacity: movie.approved ? 1 : 0.5 }}>
                                        {movie.poster_path ? (
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                                alt={movie.title || movie.name}
                                            />
                                        ) : (
                                            <div style={{ height: '140px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                                                No Image Available
                                            </div>
                                        )}
                                        <CardContent>
                                            <Typography variant="h6" component="div">
                                                {truncateText(movie.title || movie.name,25)}
                                            </Typography>
                                            <Typography variant="body1" component="div">
                                                {getMediaType(movie.media_type)} ({getReleaseDate(movie.release_date||movie.first_air_date)})
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
    </Container>
    );
};

export default Suggest;
