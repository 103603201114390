import * as React from 'react';
import { Button, Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

function getMediaType(x){
	if (x ===  "tv") {
		return "TV Series";
	}
	if (x === "movie") {
		return "Movie";
	}

	return "Misc. Media";
}

function getReleaseDate(x){
	if (typeof x == 'undefined'){
		return "Year Unknown";
	}
	return x.split('-')[0];
}

const Approval = ({ approveItem, removeItem, ariApprove, myList }) => {
    return (
        <Container sx={{width: '100%', padding:'20px'}}>
            <Typography variant="h5" gutterBottom sx={{marginBottom: '15px', textAlign: 'center', fontWeight: 700}}>Approve / Reject Suggestions</Typography>

            <div style={{ marginTop: '20px' }}>
                <Grid container spacing={2}>
                    {myList.filter(movie => !movie.approved).map((movie) => (
                        <Grid item key={movie.id} xs={12} sm={6} md={4}>
                            <Card>
                                {movie.poster_path ? (
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                        alt={movie.title || movie.name}
                                    />
                                ) : (
                                    <div style={{ height: '140px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                                        No Image Available
                                    </div>
                                )}
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        {truncateText(movie.title || movie.name,25)}
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        {getMediaType(movie.media_type)} ({getReleaseDate(movie.release_date||movie.first_air_date)})
                                    </Typography>
                                    <Button variant="contained" color="success" onClick={() => approveItem(movie)}><strong>Approve</strong></Button>
                                    <Button variant="contained" color="error" sx={{marginLeft: '5px'}} onClick={() => removeItem(movie)}><strong>Reject</strong></Button>
                                    <Button variant="contained" sx={{marginLeft: '5px'}} onClick={() => ariApprove(movie)}><strong>Ari</strong></Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>

            <Typography variant="h5" gutterBottom sx={{marginBottom: '15px', textAlign: 'center', fontWeight: 700}}>Mark as Completed</Typography>

            <div style={{ marginTop: '20px' }}>
                <Grid container spacing={2}>
                    {myList.filter(movie => movie.approved).map((movie) => (
                        <Grid item key={movie.id} xs={12} sm={6} md={4}>
                            <Card>
                                {movie.poster_path ? (
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                                        alt={movie.title || movie.name}
                                    />
                                ) : (
                                    <div style={{ height: '140px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                                        No Image Available
                                    </div>
                                )}
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        {truncateText(movie.title || movie.name,25)}
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        {getMediaType(movie.media_type)} ({getReleaseDate(movie.release_date||movie.first_air_date)})
                                    </Typography>
                                    <Button variant="contained" color="success" onClick={() => removeItem(movie)}><strong>Completed</strong></Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
    </Container>
    );
};

export default Approval;
