import * as React from 'react';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import Papa from 'papaparse';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Suggestions() {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);

  const [suggestionTableData, setSuggestionTableData] = useState([]);
  const [suggestionTableHeaders, setSuggestionTableHeaders] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch('./database.csv');
        const data = await response.text();
        const parsedData = Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
        }).data;
        setTableData(parsedData);
        if (parsedData.length > 0) {
          const headers = Object.keys(parsedData[0]);
          console.log(headers);
          setTableHeaders(headers);
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch('./suggestions.csv');
        const data = await response.text();
        const parsedData = Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
        }).data;
        setSuggestionTableData(parsedData);
        if (parsedData.length > 0) {
          const headers = Object.keys(parsedData[0]);
          console.log(headers);
          setSuggestionTableHeaders(headers);
        }
      };
      fetchData();
    }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <>
            <Tabs value={value} onChange={handleChange} sx={{'& .MuiTab-root': {textTransform: 'none', fontWeight: '700'}}} centered>
                <Tab sx={{fontSize: '16px'}} label="Ari's Personal Radar" {...a11yProps(0)} />
                <Tab sx={{fontSize: '16px'}} label="Viewer Suggestions" {...a11yProps(1)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ImageList sx={{ p:0, m:0, width: '90%', '@media (min-width:720px)': { // Change the value depending on the breakpoint you need
                        maxWidth: '720px' // Or whatever maximum width you want
                      }}}>
                      {tableData.map((row, index) => (
                        <ImageListItem key={row[tableHeaders[0]]} cols={1}>
                          <img
                            src={row[tableHeaders[0]]}
                            alt={row[tableHeaders[1]]}
                          />
                          <ImageListItemBar
                            title={<span><strong>{row[tableHeaders[1]]}</strong></span>}
                            subtitle={<span>{row[tableHeaders[3]]} ({row[tableHeaders[4]]})</span>}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ImageList sx={{p:0,m:0, width: '90%', '@media (min-width:720px)': { // Change the value depending on the breakpoint you need
                        maxWidth: '720px' // Or whatever maximum width you want
                      }}}>
                      {suggestionTableData.map((row, index) => (
                        <ImageListItem key={row[suggestionTableHeaders[0]]} cols={1}>
                          <img
                            src={row[suggestionTableHeaders[0]]}
                            alt={row[suggestionTableHeaders[1]]}
                          />
                          <ImageListItemBar
                            title={<span><strong>{row[suggestionTableHeaders[1]]}</strong></span>}
                            subtitle={<span>{row[suggestionTableHeaders[3]]} ({row[suggestionTableHeaders[4]]})<br/>Suggested by <i>{row[suggestionTableHeaders[2]]}</i></span>}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                </div>
            </CustomTabPanel>
      </>
  );
}

export default Suggestions;
