import './App.css';
import Suggestions from './Suggestions';
import Suggest from './Suggest';
import About from './About';
import Approval from './Approval';
import CustomBar from './CustomBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [globalList, setGlobalList] = useState([]);
  const base_url = 'https://eliteari.pythonanywhere.com';
  //const base_url = 'http://localhost:5000';

  // Function to fetch the current list of items
  const fetchItems = async () => {
    const response = await axios.get(`${base_url}/getlist`);
    setGlobalList(response.data);
    console.log(response.data);
  };

  const addToList = async (movie) => {
    try {
        await axios.post(`${base_url}/additem`, { item: movie });
        fetchItems(); // Refresh the list after adding
    } catch (error) {
        console.log(error.response.data.message);
    }
  };

  const approveItem = async (movie) => {
    try {
        await axios.post(`${base_url}/approveitem`, { item: movie });
        fetchItems(); // Refresh the list after adding
    } catch (error) {
        console.log(error.response.data.message);
    }
  };

  const ariApprove = async (movie) => {
    try {
        await axios.post(`${base_url}/ariapprove`, { item: movie });
        fetchItems(); // Refresh the list after adding
    } catch (error) {
        console.log(error.response.data.message);
    }
  };

  const removeItem = async (movie) => {
    try {
        await axios.post(`${base_url}/removeitem`, { item: movie });
        fetchItems(); // Refresh the list after adding
    } catch (error) {
        console.log(error.response.data.message);
    }
  };

  // Fetch items on initial render
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Router>
        <CustomBar/>
        <Routes>
            <Route path="/" element={<Suggest addToList={addToList} myList={globalList} baseUrl={base_url}/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/suggest" element={<Suggest addToList={addToList} myList={globalList} baseUrl={base_url}/>}/>
            <Route path="/suggestions" element={<Suggestions/>}/>
            <Route path="/approval" element={<Approval approveItem={approveItem} removeItem={removeItem} ariApprove={ariApprove} myList={globalList} />}/>
        </Routes>
    </Router>
  );

}

export default App;
